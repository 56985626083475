





















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import FolderList from "../../components/folder-list.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    FolderList,
  },
})
export default class Name extends Vue {
  private data: any = [
    [
      {
        title: "定性特征参数分析",
        type: "定性",
        description:
          "舌图定性特征的描述性统计、差异性分析，一键式可视化展示，包含舌色、苔色、齿痕、裂纹、厚薄、腐腻等指标分析。",
        img: "/analysis/定性特征.png",
        isTese: false,
      },
      {
        title: "颜色特征参数分析",
        type: "颜色",
        description:
          "舌图颜色特征参数的描述性统计、差异性分析，一键式可视化展示，包含LAB、RGB、HSV参数分析。",
        img: "/analysis/颜色特征.png",
        isTese: true,
      },
      {
        title: "几何特征参数分析",
        type: "几何",
        description:
          "舌图几何特征参数的描述性统计、差异性分析，一键式可视化展示，包含齿痕面积及数量、裂纹长度及数量等参数分析。",
        img: "/analysis/几何特征.png",
        isTese: true,
      },
    ],
    [
      {
        title: "纹理特征参数分析",
        type: "纹理",
        description:
          "舌图纹理特征参数的描述性统计、差异性分析，一键式可视化展示，包含灰度共生特征、灰度尺寸区域特征等参数分析。",
        img: "/analysis/纹理特征.png",
        isTese: true,
      },
      {
        title: "舌图演变规律分析",
        type: "舌图",
        description:
          "不同病程下，舌象的演变规律，包含定性特征、颜色特征参数、几何特征参数、纹理特征参数等分析。",
        img: "/analysis/舌图演变.png",
        isTese: true,
      },
      {},
    ],
  ];
  private goToResult(item: any) {
    return;
    this.$router.push({
      path: "/main/analysis/shetu/shetu-result",
      query: {
        type: item,
      },
    });
  }
}
