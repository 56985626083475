















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private goToResult(item: any) {
    this.$emit("goToResult", item);
  }
}
